import { getEnvValue, getEnv } from '../utils/get-env';

function getEnvLabel(env) {
  if (['prod', 'production'].includes((env || '').toLowerCase())) {
    return 'beta';
  } else {
    return env;
  }
}
const appConfig = {
  name: getEnvValue('REACT_APP_NAME', false),
  key: getEnvValue('REACT_APP_PROJECT_ID'),
  env: getEnvLabel(getEnv()),
  emulator: getEnvValue('REACT_APP_EMULATOR', false),
  slack: {
    url: getEnvValue('REACT_APP_SLACK_CHANNEL', false),
    name: getEnvValue('REACT_APP_SLACK_CHANNEL_NAME', false),
  },
  properties: [
    { name: 'WSJ', key: 'wsj' },
    { name: "Barron's", key: 'barrons' },
    { name: 'MarketWatch', key: 'marketwatch' },
    { name: 'Mansion Global', key: 'mansionglobal' },
    { name: 'FN London', key: 'fnlondon' },
    { name: 'Private Equity News', key: 'pen' },
    { name: "Investor's Business Daily", key: 'ibd' },
  ],
  requestAccess: {
    url: 'https://hooks.slack.com/services/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',
  },
};

const authConfig = {
  issuer: getEnvValue('REACT_APP_OKTA_ISSUER'),
  clientId: getEnvValue('REACT_APP_OKTA_CLIENT_ID'),
  redirectUri: getEnvValue('REACT_APP_OKTA_REDIRECT_URI'),
};

export { appConfig, authConfig };
