import React, { useState, useEffect, useContext, useMemo } from 'react';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { useAuth } from '@screentone/addon-auth-wrapper';

import { appConfig } from '../../config/app-config';
import { firebaseConfig } from '../../config/firebase-config';
import * as API from '../../modules/api';

const SLACK_CHANNEL_NAME = appConfig.slack.name;
const SLACK_CHANNEL_URL = appConfig.slack.url;
// Use a firebase https Callable function to mint the token server side in a firebase cloud function
// more secure then using a firebase cloud function http end point

const FirebaseContext = React.createContext(null);

function useFirebase() {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error("Can't use without FirebaseProvider!");
  }
  return context;
}

function FirebaseProvider({ children }) {
  const { user, authState } = useAuth();
  const [firestore, setFirestore] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [storage, setStorage] = useState(null);

  const { isAuthenticated, accessToken } = authState;

  useEffect(() => {
    async function initialize() {
      try {
        firebase.initializeApp(firebaseConfig);

        const newAccessToken = accessToken.value;
        const CUSTOM_TOKEN_ENDPOINT = '/firebaseCustomToken';
        const firebaseTokenResponse = await fetch(CUSTOM_TOKEN_ENDPOINT, {
          headers: {
            Authorization: `Bearer ${newAccessToken}`,
          },
        });
        const resp = await firebaseTokenResponse.json();
        const { token } = await resp;
        // https://firebase.google.com/docs/auth/web/custom-auth#authenticate-with-firebase

        await firebase.auth().signInWithCustomToken(token);
        // TODO: safter we call signInWithCustomToken we don't do anything with the result.
        // the user we pass to API should be the user from OKTA or from the firebase custom stop verification step?
        // TODO: Also should there be some logic if this fails to log out? eg user from DJ/WSJ that is not on list of users for this app.
        await firebase.auth().currentUser.updateEmail(user.email.toLowerCase());
        setFirestore(firebase.firestore());
        setAnalytics(firebase.analytics());
        setStorage(firebase.storage());
        // TODO: is there anything else from the user we can log into the analytics here?
        // console log user and see what else is associated with it.
        if (appConfig.emulator) {
          firebase.firestore().useEmulator('localhost', 8080);
          firebase.functions().useEmulator('localhost', 5001);
          firebase.storage().useEmulator('localhost', 9199);
        }
      } catch (error) {
        console.error('error', error, JSON.stringify(error, null, 2));

        alert(`Please contact the #${SLACK_CHANNEL_NAME} team on slack`);
      }
    }

    const isLoggedIn = isAuthenticated && user && accessToken;
    if (isLoggedIn && firebase.apps.length === 0) {
      initialize();
    }
  }, [accessToken, isAuthenticated, user]);

  const api = useMemo(() => {
    if (!user || !firestore || !storage || !analytics) {
      return null;
    }
    // TODO: not sure if this is the best way to do this.
    // But since we are using the emails as keys in firestore db. we want those to be consistent and lower case
    const oktaUser = { ...user, email: user.email.toLocaleLowerCase() };
    console.info('oktaUser', oktaUser);
    const { name, family_name, given_name, zoneinfo, locale } = oktaUser;
    analytics.setUserProperties({
      email: user.email.toLowerCase(),
      name,
      family_name,
      given_name,
      zoneinfo,
      locale,
    });
    analytics.logEvent('user_logged_in', { email: user.email.toLowerCase() });
    return API.initialize({ oktaUser, firestore, storage, analytics, firebase });
  }, [analytics, firestore, storage, user]);

  const value = {
    api,
    analytics,
    slackName: SLACK_CHANNEL_NAME,
    slackUrl: SLACK_CHANNEL_URL,
  };

  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
}

export { useFirebase };
export default FirebaseProvider;
