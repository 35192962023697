import { getEnvValue } from '../utils/get-env';

const firebaseConfig = {
  apiKey: getEnvValue('REACT_APP_API_KEY'),
  authDomain: getEnvValue('REACT_APP_AUTH_DOMAIN'),
  projectId: getEnvValue('REACT_APP_PROJECT_ID'),
  storageBucket: getEnvValue('REACT_APP_EMULATOR')
    ? getEnvValue('REACT_APP_EMULATOR_STORAGE_BUCKET')
    : getEnvValue('REACT_APP_STORAGE_BUCKET'),
  messagingSenderId: getEnvValue('REACT_APP_MESSAGING_SENDER_ID'),
  appId: getEnvValue('REACT_APP_APP_ID'),
  measurementId: getEnvValue('REACT_APP_MEASUREMENT_ID'),
};

export { firebaseConfig };
