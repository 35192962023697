import { v4 as uuid4 } from 'uuid';
import { deserializeTranscript, serializeTranscript } from '@pietrop/serialize-stt-words';

// Initializes the API, closing over the user and firebase public API variables
function initialize({ oktaUser, firestore, storage, analytics, firebase }) {
  async function getAllProjects(property) {
    analytics.logEvent('getAllProjects', { email: oktaUser.email });

    // TODO: refactor to use async/await
    return new Promise((resolve, reject) => {
      firestore
        .collection('projects')
        // Temporary solution to match users with projects.
        // TODO: figure out how to enforce these in firestore.
        // There seems to be a known bug in firestore using `get` and `exists` in firestore rules?
        // TODO: also figure out how to extended/modify this, to support multiple users on the same project? eg array instead of string. Also in project create //
        // The TODO was implemented.
        .where('authors', 'array-contains', oktaUser.email)
        .where('property', '==', property)
        .get()
        .then((querySnapshot) => {
          const list = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            const tmpData = { ...data, id: doc.id };
            list.push(tmpData);
          });
          return list;
        })
        .then(async (projects) => {
          for (let project of projects) {
            const { transcripts } = await getTranscripts(project.id);
            project.transcripts = transcripts;
          }
          resolve(projects);
        })
        .catch(reject);
    });
  }

  async function getProject(id) {
    analytics.logEvent('getProject', { projectId: id });
    // TODO: refactor to use async/await
    return new Promise((resolve, reject) => {
      const docRef = firestore.collection('projects').doc(id);
      docRef
        .get()
        .then((doc) => {
          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/in
          if (doc.exists) {
            const projectData = doc.data();

            let isOwner = false;
            if (projectData.roles[oktaUser.email]) {
              if (projectData.roles[oktaUser.email] === 'owner') {
                isOwner = true;
              }
            }

            projectData.isOwner = isOwner;

            const tmpResult = { project: projectData };
            // TODO: also need to get transcript associated with project
            // TODO: first do the create transcript within a project ApiWrapper
            // TODO: also need to get paper-edits for project
            resolve(tmpResult);
          } else {
            console.log('No such document! getProject 1');
            reject('No such document! getProject 2');
          }
        })
        .catch((error) => {
          console.log('Error getting document getProject: 3', error);
          reject('No such document! getProject 4');
        });
    });
  }

  async function createProject(data, currentProperty) {
    const currentUserEmail = oktaUser.email;
    analytics.logEvent('createProject', { email: currentUserEmail });
    const roles = {};
    const authors = [currentUserEmail];
    roles[currentUserEmail] = 'owner';
    // TODO: refactor to use async/await
    return new Promise((resolve, reject) => {
      const created = firebase.firestore.FieldValue.serverTimestamp();
      const updated = firebase.firestore.FieldValue.serverTimestamp();
      firestore
        .collection('projects')
        .add({
          roles,
          authors,
          title: data.title,
          description: data.description,
          created,
          updated,
          property: currentProperty,
        })
        .then(async (docRef) => {
          console.log('Document written with ID: ', docRef.id);
          const response = {};
          response.status = 'ok';
          response.project = {
            id: docRef.id,
            title: data.title,
            description: data.description,
            // time stamps are created server side
            // so adding them here not coming from firestore
            // would just add the object.
            created: '',
            updated: '',
          };

          // create default label for project
          // await this.createLabel(docRef.id, DEFAULT_LABEL);

          resolve(response);
        })
        .catch(function(error) {
          console.error('Error adding document: ', error);
          reject(error);
        });
    });
  }

  async function updateProject(id, data) {
    // TODO: should pass data?
    analytics.logEvent('updateProject', { id });
    // TODO: refactor to use async/await
    return new Promise((resolve, reject) => {
      const docRef = firestore.collection('projects').doc(id);

      const tmpData = data;

      // TODO: avoid parameter reference mutation
      tmpData.updated = firebase.firestore.FieldValue.serverTimestamp();
      console.log('updateProject tmpData', tmpData);
      // tmpData.roles = roles;
      docRef
        // .set(tmpData, { merge: isMerge })
        .update(tmpData)
        .then(() => {
          resolve({ status: 'ok', project: tmpData });
        })
        .catch((error) => {
          console.error('Error getting document:', error);
          reject('No such document updateProject!');
        });
    });
  }

  async function deleteProject(id) {
    analytics.logEvent('deleteProject', { id });
    // TODO: refactor to use async/await
    return new Promise((resolve, reject) => {
      firestore
        .collection('projects')
        .doc(id)
        .delete()
        .then(() => {
          console.log('Document successfully deleted!');
          resolve({ ok: true });
        })
        .catch((error) => {
          console.error('Error removing document: ', error);
          reject(error);
        });
    });
  }

  /**
   * Transcripts
   */
  async function getTranscripts(projectId) {
    analytics.logEvent('getTranscripts', { projectId });
    // TODO: refactor to use async/await
    return new Promise((resolve, reject) => {
      const transcriptRef = firestore
        .collection('projects')
        .doc(projectId)
        .collection('transcripts');

      transcriptRef
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length > 0) {
            const transcripts = querySnapshot.docs.map((doc) => {
              // console.log('doc.data()', doc.data(), doc.id);
              const tmpData = doc.data();

              // Add the individual transcript firebase id to the data
              // console.log('tmpData getTranscripts', tmpData);
              return { ...tmpData, id: doc.id };
            });

            resolve({
              ok: true,
              transcripts,
            });
          }

          resolve({
            ok: false,
            transcripts: [],
          });
        })
        .catch(function(error) {
          console.log('Error getting documents: ', error);
          reject(error);
        });
    });
  }

  async function createTranscript(projectId, formData, updateProgressValue) {
    // TODO: we want to log the file extension, eg to know are they uploading mostly audio or video
    // TODO: we also want to log file duration
    // TODO: we also want to log file size if possible

    // sending file to google cloud storage
    const title = formData.get('title');
    const description = formData.get('description');
    const type = formData.get('type');
    const clipName = formData.get('file').name;
    const mediaType = formData.get('file').mediaType;
    const originalFileExtension = formData.get('file').originalFileExtension;

    analytics.logEvent('createTranscript', { projectId, mediaType, originalFileExtension });
    const selectedFile = formData.get('file');

    const storageRefFileName = `${uuid4()}-${clipName}`;
    //add folder path, eg /{projectId}/...
    const storageRefName = `${projectId}/${storageRefFileName}`;
    const storageRef = storage.ref();
    const fileRef = storageRef.child(storageRefName);
    // TODO: could add metadata - firebase.storage.child(path).put(file, metadata);  ?
    const uploadTask = fileRef.put(selectedFile);

    return new Promise((resolve, reject) => {
      // First upload the file
      // then create the firestore entity
      // to avoid race condition, with the cloud function
      // that is triggered on transcript create. In case media is not available coz still uploading.

      // https://firebase.google.com/docs/storage/web/upload-files
      // Listen for state changes, errors, and completion of the upload.
      // https://firebase.google.com/docs/storage/web/upload-files
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function(snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          updateProgressValue(progress);
          // setUploadProgress(progress);
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
            default:
              console.error('Error with upload to firebase');
              break;
          }
        },
        function(error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              console.error(error.code);
              break;
            case 'storage/canceled':
              // User canceled the upload
              console.error(error.code);
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              console.error(error.code);
              break;
            default:
              console.error('Error with upload to firebase', error);
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            const newTranscript = {
              projectId,
              title,
              description,
              type,
              clipName,
              storageRefName,
              downloadURL,
              display: true,
              // paragraphs: [],
              // words: [],
              status: 'in-progress',
              created: firebase.firestore.FieldValue.serverTimestamp(),
              updated: firebase.firestore.FieldValue.serverTimestamp(),
            };
            // create firebase entity
            firestore
              .collection('projects')
              .doc(projectId)
              .collection('transcripts')
              .add(newTranscript)
              .then(async (docRef) => {
                console.log('Document written with ID: ', docRef.id);
                const response = {};
                response.status = 'ok';
                response.transcriptId = docRef.id;
                response.transcript = {
                  ...newTranscript,
                  id: docRef.id,
                  // overriding created and updated from server
                  // workaround, similat to what done for create projects
                  created: '',
                  updated: '',
                };

                resolve(response);
                // updating metadata with custom fields, to
                // set with project and transcript it belongs to
                // This triggers cloud function to create mp4 preview
                // and update firestore with ref of the mp4 video preview
                // fileRef
                //   .updateMetadata({
                //     customMetadata: {
                //       projectId: projectId,
                //       transcriptId: docRef.id,
                //     },
                //   })
                //   .then(resp => {
                //      resolve(resp);
                // })
                // .catch(er => {
                //   reject(er);
                // });
              })
              .catch(function(error) {
                console.error('Error adding document: ', error);
                reject(error);
              });
            // end - create firebase entity
          });
        }
      );
    });
  }

  async function getTranscript(projectId, transcriptId, queryParamsOptions) {
    return new Promise(async (resolve, reject) => {
      const projectRef = firestore.collection('projects').doc(projectId);
      const project = await projectRef.get();
      const projectData = project.data();

      const transcriptRef = firestore
        .collection('projects')
        .doc(projectId)
        .collection('transcripts')
        .doc(transcriptId);

      transcriptRef
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            const tmpData = doc.data();
            // TODO: if add languages, this is where could log
            const { metadata, type, sttEngine, created } = tmpData;
            let duration = 'NA';
            if (metadata && metadata.duration) {
              duration = metadata.duration;
            }
            analytics.logEvent('getTranscript', {
              projectId,
              transcriptId,
              durationInSeconds: duration,
              type,
              sttEngine,
              created,
            });
            // In casee the url of the media expires, getting a new getDownloadURL from ref in cloud storage
            let pathReference = storage.ref(tmpData.storageRefName);
            if (tmpData.videoUrl) {
              pathReference = storage.ref(tmpData.videoUrl);
            } else if (tmpData.audioUrl) {
              pathReference = storage.ref(tmpData.audioUrl);
            }

            // Getting collections for words and transcripts and serializing it back into a transcript
            //  wordStartTimes, wordEndTimes, textList, paragraphStartTimes, paragraphEndTimes, speakersLit;
            const wordStartTimesRef = firestore
              .collection('projects')
              .doc(projectId)
              .collection('transcripts')
              .doc(transcriptId)
              .collection('words')
              .doc('wordStartTimes');
            const wordStartTimesRefSnaphot = await wordStartTimesRef.get();
            const wordStartTimes = await wordStartTimesRefSnaphot.data().wordStartTimes;

            const wordEndTimesRef = firestore
              .collection('projects')
              .doc(projectId)
              .collection('transcripts')
              .doc(transcriptId)
              .collection('words')
              .doc('wordEndTimes');
            const wordEndTimesRefSnaphot = await wordEndTimesRef.get();
            const wordEndTimes = await wordEndTimesRefSnaphot.data().wordEndTimes;

            const textListRef = firestore
              .collection('projects')
              .doc(projectId)
              .collection('transcripts')
              .doc(transcriptId)
              .collection('words')
              .doc('textList');
            const textListRefSnaphot = await textListRef.get();
            const textList = await textListRefSnaphot.data().textList;

            const paragraphStartTimesRef = firestore
              .collection('projects')
              .doc(projectId)
              .collection('transcripts')
              .doc(transcriptId)
              .collection('words')
              .doc('paragraphStartTimes');
            const paragraphStartTimesRefSnaphot = await paragraphStartTimesRef.get();
            const paragraphStartTimes = await paragraphStartTimesRefSnaphot.data()
              .paragraphStartTimes;

            const paragraphEndTimesRef = firestore
              .collection('projects')
              .doc(projectId)
              .collection('transcripts')
              .doc(transcriptId)
              .collection('words')
              .doc('paragraphEndTimes');
            const paragraphEndTimesRefSnaphot = await paragraphEndTimesRef.get();
            const paragraphEndTimes = await paragraphEndTimesRefSnaphot.data().paragraphEndTimes;

            const speakersLitRef = firestore
              .collection('projects')
              .doc(projectId)
              .collection('transcripts')
              .doc(transcriptId)
              .collection('words')
              .doc('speakersLit');
            const speakersLitRefSnaphot = await speakersLitRef.get();
            const speakersLit = await speakersLitRefSnaphot.data().speakersLit;

            const transcript = deserializeTranscript({
              wordStartTimes: JSON.parse(wordStartTimes),
              wordEndTimes: JSON.parse(wordEndTimes),
              textList: JSON.parse(textList),
              paragraphStartTimes: JSON.parse(paragraphStartTimes),
              paragraphEndTimes: JSON.parse(paragraphEndTimes),
              speakersLit: JSON.parse(speakersLit),
            });
            // console.log('transcript', transcript);
            // TODO: or could get it from the audio that is sent to STT
            // to ensure HTML5 compatibility, if non HTML5 audio/video is being uploaded as source file
            // const pathReference = storage.ref(tmpData.audioUrl);

            let isEditable = false;
            if (projectData.roles[oktaUser.email]) {
              if (
                projectData.roles[oktaUser.email] === 'write' ||
                projectData.roles[oktaUser.email] === 'owner'
              ) {
                isEditable = true;
              }
            }

            pathReference
              .getDownloadURL()
              .then((url) => {
                const tmpResult = {
                  id: doc.id,
                  projectTitle: projectData.title,
                  isEditable: isEditable,
                  transcriptTitle: tmpData.title,
                  // TODO: integrate with transcript json data
                  // transcript: { paragraphs: tmpData.paragraphs, words: tmpData.words },

                  transcript,
                  // TODO: integrate with cloud storage url data
                  // TODO: change this for url of video / audio preview (mp4 or wav)
                  // url: tmpData.downloadURL,
                  url,
                  // TODO: add clipName
                  clipName: tmpData.clipName,
                  status: tmpData.status,
                  sttEngine: tmpData.sttEngine,
                  sttProgressPercent: tmpData.sttProgressPercent
                    ? null
                    : tmpData.sttProgressPercent,
                };
                // TODO: also need to get transcript associated with project
                // TODO: first do the create transcript within a project ApiWrapper
                // TODO: also need to get paper-edits for project
                resolve(tmpResult);
              })
              .catch(function(error) {
                // Handle any errors
                console.log('No such document! getTranscript');
                reject('No such document! getTranscript');
              });
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document! getTranscript');
            reject('No such document! getTranscript');
          }
        })
        .catch(function(error) {
          console.log('Error getting document: getTranscript', error);
          reject('No such document! getTranscript error');
        });
    });
  }

  async function updateTranscript(projectId, transcriptId, queryParamsOptions, data) {
    analytics.logEvent('updateTranscript', { projectId, transcriptId });
    console.log('projectId', projectId);
    console.log('transcriptId', transcriptId);
    console.log('queryParamsOptions', queryParamsOptions);
    console.log('data', data);
    // TODO: there mgiht be a better way to do this.
    // this being avoiding sending client side info to the
    const { display, status } = data;
    delete data.display;
    delete data.status;
    // console.log('updateTranscript', projectId, transcriptId, queryParamsOptions, data);
    // const res = await corsFetch(this.transcriptsIdUrl(projectId, transcriptId, queryParamsOptions), 'PUT', data);
    // if only updating title or descrition

    // return res;
    // console.log('queryParamsOptions, data', queryParamsOptions, data);
    return new Promise(async (resolve, reject) => {
      const docRef = firestore
        .collection('projects')
        .doc(projectId)
        .collection('transcripts')
        .doc(transcriptId);

      if (data.words && data.paragraphs) {
        const tmpData = data;
        const { words, paragraphs } = data;
        const {
          wordStartTimes,
          wordEndTimes,
          textList,
          paragraphStartTimes,
          paragraphEndTimes,
          speakersLit,
        } = serializeTranscript({
          words,
          paragraphs,
        });
        console.log('words', words);
        console.log('paragraphs', paragraphs);
        delete tmpData.words;
        delete tmpData.paragraphs;

        await firestore
          .collection('projects')
          .doc(projectId)
          .collection('transcripts')
          .doc(transcriptId)
          .collection('words')
          .doc('wordStartTimes')
          .set(
            {
              wordStartTimes: JSON.stringify(wordStartTimes),
            },
            {
              merge: true,
            }
          );

        await firestore
          .collection('projects')
          .doc(projectId)
          .collection('transcripts')
          .doc(transcriptId)
          .collection('words')
          .doc('wordEndTimes')
          .set(
            {
              wordEndTimes: JSON.stringify(wordEndTimes),
            },
            {
              merge: true,
            }
          );

        await firestore
          .collection('projects')
          .doc(projectId)
          .collection('transcripts')
          .doc(transcriptId)
          .collection('words')
          .doc('textList')
          .set(
            {
              textList: JSON.stringify(textList),
            },
            {
              merge: true,
            }
          );

        await firestore
          .collection('projects')
          .doc(projectId)
          .collection('transcripts')
          .doc(transcriptId)
          .collection('words')
          .doc('paragraphStartTimes')
          .set(
            {
              paragraphStartTimes: JSON.stringify(paragraphStartTimes),
            },
            {
              merge: true,
            }
          );

        await firestore
          .collection('projects')
          .doc(projectId)
          .collection('transcripts')
          .doc(transcriptId)
          .collection('words')
          .doc('paragraphEndTimes')
          .set(
            {
              paragraphEndTimes: JSON.stringify(paragraphEndTimes),
            },
            {
              merge: true,
            }
          );

        await firestore
          .collection('projects')
          .doc(projectId)
          .collection('transcripts')
          .doc(transcriptId)
          .collection('words')
          .doc('speakersLit')
          .set(
            {
              speakersLit: JSON.stringify(speakersLit),
            },
            {
              merge: true,
            }
          );

        docRef
          .set(data, { merge: true })
          .then((doc) => {
            // TODO: inconsistencies in the interface, some return ok boolean attribute, others status 'ok' string
            data.status = status;
            data.display = display;
            resolve({ ok: true, status: 'ok', transcript: data });
          })
          .catch((error) => {
            console.log('Error getting document:', error);
            reject('No such document updateTranscript!');
          });
        // TODO: if editing transcript, title, description in transcript list view
      } else {
        docRef
          .set(data, { merge: true })
          .then((doc) => {
            // TODO: inconsistencies in the interface, some return ok boolean attribute, others status 'ok' string
            data.status = status;
            data.display = display;
            resolve({ ok: true, status: 'ok', transcript: data });
          })
          .catch((error) => {
            console.log('Error getting document:', error);
            reject('No such document updateTranscript!');
          });
      }
    });
  }

  async function deleteTranscript(projectId, transcriptId) {
    analytics.logEvent('deleteTranscript', { projectId, transcriptId });
    return new Promise(async (resolve, reject) => {
      const projectsRef = firestore.collection('projects').doc(projectId);
      const transcriptRef = projectsRef.collection('transcripts').doc(transcriptId);
      console.log('transcriptRef', transcriptRef);
      transcriptRef
        .delete()
        .then(() => {
          console.log('Document successfully deleted!');
          resolve({ ok: true });
        })
        .catch((error) => {
          console.error('Error removing document: ', error);
          reject(error);
        });
    });
  }

  async function submitFeedback(data) {
    console.log('submitFeedback', data);
    const currentUserEmail = oktaUser.email;
    analytics.logEvent('submitFeedback', { email: currentUserEmail, ...data });

    // TODO: refactor to use async/await
    return new Promise((resolve, reject) => {
      const created = firebase.firestore.FieldValue.serverTimestamp();
      firestore
        .collection('feedback')
        .add({
          user: currentUserEmail,
          ...data,
          created,
        })
        .then(async (docRef) => {
          console.log('Document written with ID: ', docRef.id);
          const response = {};
          response.status = 'ok';

          // create default label for project
          // await this.createLabel(docRef.id, DEFAULT_LABEL);

          resolve(response);
        })
        .catch(function(error) {
          console.error('Error adding document: ', error);
          reject(error);
        });
    });
  }

  const API = Object.freeze({
    getAllProjects,
    getProject,
    createProject,
    updateProject,
    deleteProject,

    getTranscripts,
    createTranscript,
    getTranscript,
    updateTranscript,
    deleteTranscript,
    submitFeedback,
  });

  return API;
}

export { initialize };
