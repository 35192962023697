const ALLOWED_ENVS = ['local', 'dev', 'uat', 'prod'];

/**
 * Returns the application environment
 */
function getEnv() {
  const env = process.env.REACT_APP_NODE_ENV.toLowerCase();
  if (ALLOWED_ENVS.includes(env) === false) {
    throw new Error(`Environment "${env}" not supported!`);
  }

  return env;
}

/**
 * Returns the value of an environment variable by conditionally appending
 * the current environment as suffix.
 *
 * @param {string} Name of the env variable without the environment prefix
 * @param {boolean} shouldUseCurrentEnv `false` for global variables access
 */
function getEnvValue(prefix, shouldUseCurrentEnv = true) {
  let suffix = '';

  if (shouldUseCurrentEnv) {
    suffix = `_${getEnv().toUpperCase()}`;
  }

  const key = `${prefix}${suffix}`;
  const value = process.env[key];

  if (!value) {
    console.warn(`Attempt to read missing ENV value: ${key}`);
    return null;
  }

  return value;
}

export { getEnv, getEnvValue };
