import React from 'react';
import { IconSymbol, PageWrapper, Loader } from '@screentone/core';
import { AuthRouter, ProtectedRoute } from '@screentone/addon-auth-wrapper';

import { appConfig, authConfig } from './config/app-config';
import FirebaseProvider from './Providers/Firebase';
import CustomAlert from './Components/shared/CustomAlert';
import { useOnlineStatus } from './hooks/useOnlineStatus';

const Project = React.lazy(() => import('./Components/pages/Project'));
const Projects = React.lazy(() => import('./Components/pages/Projects'));
const TranscriptCorrect = React.lazy(() => import('./Components/pages/TranscriptCorrect'));

function App() {
  const online = useOnlineStatus();

  return (
    <>
      <IconSymbol />

      {!online && (
        <PageWrapper>
          <CustomAlert
            variant={'warning'}
            heading={'Offline warning'}
            message={"You don't seem to be connected to the internet "}
          />
        </PageWrapper>
      )}

      <React.Suspense
        fallback={() => (
          <div style={{ position: 'fixed', top: '50%', left: ' 50%' }}>
            <Loader />
          </div>
        )}
      >
        <AuthRouter app={appConfig} authConfig={authConfig} provider={FirebaseProvider}>
          <ProtectedRoute exact path="/:property" component={Projects} />{' '}
          <ProtectedRoute exact path="/:property/:projectId" component={Project} />
          <ProtectedRoute
            exact
            path={[
              '/:property/:projectId/transcripts/:transcriptId/correct',
              '/:property/:projectId/transcripts/:transcriptId',
            ]}
            component={TranscriptCorrect}
          />
        </AuthRouter>
      </React.Suspense>
    </>
  );
}

export default App;
